<template>
  <div class="countdown">
    <div class="countdown-info">
      Token ważny przez 
      <span class="countdown-value">{{ counter | minutesAndSeconds }}</span>
      <button @click="refresh">
        <svg class="icc countdown-btn">
          <use href="../assets/feather-sprite.svg#rotate-ccw"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Countdown",
  props: ["counter"],
  data() {
    return {
      countdown: this.counter,
    };
  },
  // computed: {
  //   countdown () {
  //     return this.counter;
  //   }
  // },
  filters: {
    minutesAndSeconds(value) {
      const minutes = Math.floor(parseInt(value, 10) / 60);
      const seconds = parseInt(value, 10) - minutes * 60;
      const secondsData = seconds < 10 ? `0${seconds}` : seconds;
      return `${minutes}:${secondsData}`;
    },
  },
  methods: {
    refresh () {
      this.$emit('refreshToken');
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown-info {
  color: #777;
  font-size: .7rem;
  font-weight: bold;
}
.countdown-btn {
  vertical-align: middle;
  margin-left: 6px;
}
</style>