import axios from 'axios';
import store from "@/store";
import { AUTH_API_URL } from "@/data/constants";

export default function () {
  const JWT_TOKEN = store.getters.getJwtToken;
  return new Promise((resolve, reject) => {
    if (AUTH_API_URL) {
      axios({
        method: 'post',
        baseURL: AUTH_API_URL,
        url: '/refresh',
        data: null,
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${JWT_TOKEN}`
        }
      }).then(res => {
        // success
        resolve(res.data);
      }).catch(err => {
        // error
        let errData = err;
        if (err.response) {
          if (error.response.status == 401) {
            // router.replace({
            //   name: 'Dashboard'
            // });
            store.commit('putAuthState', false);
          }
          if (error.response.status == 400) {
            errData = error.response.data.data.errors;
          }
        }
        reject(errData);
      });
    }
  });
}