<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header header">
        <div class="header-title">
          <slot name="header">Autoryzacja</slot>
        </div>
      </header>
      <section class="modal-body">
        <slot name="body">
          <div class="login-section">
            <h1 class="sr-text">Logowanie</h1>
            <ul class="login-section__form form">
              <li>
                <label>
                  Login
                  <input type="text" v-model="auth.login" />
                </label>
              </li>
              <li>
                <label>
                  Hasło
                  <input type="password" v-model="auth.password" />
                </label>
              </li>
              <li v-if="authMessage != ''">
                <p>{{ authMessage }}</p>
              </li>
              <li>
                <button class="login-button" type="submit" @click="action">
                  Zaloguj się
                </button>
              </li>
            </ul>
          </div>
        </slot>
      </section>
      <!-- <footer class="modal-footer">
        <slot name="footer">Wymagane logowanie</slot>
      </footer> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["auth", "authMessage"],
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  // background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  min-width: 360px;
}

.header {
  color: #fff;
  display: inline-block;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-family: Helvetica, sans-serif;
  height: 160px;
  position: relative;
}

.header-title {
  display: inline-block;
  font-size: 2em;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-section {
  height: 340px;
  font-family: Helvetica, sans-serif;
  background-color: #3a89c9;
  position: relative;
  padding-top: 10px;
}

.login-section::after,
.login-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateZ(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.login-section::before {
  background-color: #eee;
  width: 40px;
  height: 40px;
}

.login-section::after {
  background-color: #3a89c9;
  width: 20px;
  height: 20px;
}

.login-section__title {
  font-size: 1.1rem;
  color: #eee;
}

.login-section__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

// @media (min-width: 600px) {
//     .login-section__form {
//         -webkit-box-orient:horizontal;
//         -webkit-box-direction: normal;
//         -ms-flex-direction: row;
//         flex-direction: row;
//         -webkit-box-align: end;
//         -ms-flex-align: end;
//         align-items: flex-end;
//         -webkit-box-pack: center;
//         -ms-flex-pack: center;
//         justify-content: center
//     }
// }
.form {
  padding: 0;
  font-size: 0.8rem;
  margin: 1.2rem 0 0 0;
}

.form > li {
  display: block;
  margin-top: 1em;
}

.form label {
  display: block;
  text-align: left;
  color: #eee;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  max-width: 200px;
}

.form input[type="password"],
.form input[type="text"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #bebebe;
  padding: 7px;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  margin-top: 0.2rem;
}

.form input[type="password"]:focus,
.form input[type="text"]:focus {
  -webkit-box-shadow: 0 0 8px #3a89c9;
  box-shadow: 0 0 8px #3a89c9;
  border: 1px solid #3a89c9;
}

.login-button {
  border: 1px #1f396b solid;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 20px;
  display: inline-block;
  line-height: 1.5em;
  font-size: 0.7rem;
  letter-spacing: 1px;
  font-weight: 700;
  color: #eee;
  background: #223f77;
  padding: 8px 10px;
}

.login-button:hover {
  text-decoration: none;
  background: #284a8b;
  color: #fff;
}

.flash {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  color: #dc143c;
}
</style>