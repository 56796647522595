<template>
  <div class="dashboard-section">
    <div class="cntr cntr-wide">
      <h1>Pulpit</h1>
      <p>Witaj, redaktorze!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  methods: {
  }
}
</script>
