<template>
  <nav class="nav">
    <div class="cntr nav-wrap">
      <div class="nav-sec">
        <router-link class="nav-link" to="/">Pulpit</router-link>
        <router-link class="nav-link" to="/posty">Posty</router-link>
        <!-- <router-link class="nav-link" to="/konfiguracja">Konfiguracja</router-link> -->
      </div>
      <div class="nav-sec" v-if="authState">
        <a type="button" class="nav-button" :href="hrefs.websiteUrl" target="_blank">
          <svg class="icc icc-btn">
            <use href="../assets/feather-sprite.svg#eye"></use>
          </svg>
          <div class="nav-hint">Witryna</div>
        </a>
        <a type="button" class="nav-button" :href="hrefs.mailUrl" target="_blank">
          <svg class="icc icc-btn">
            <use href="../assets/feather-sprite.svg#mail"></use>
          </svg>
          <div class="nav-hint">Mail</div>
        </a>
        <a type="button" class="nav-button" :href="hrefs.statsUrl" target="_blank">
          <svg class="icc icc-btn">
            <use href="../assets/feather-sprite.svg#trending-up"></use>
          </svg>
          <div class="nav-hint">Statystyki</div>
        </a>
        <a type="button" class="nav-button" :href="hrefs.searchConsoleUrl" target="_blank">
          <svg class="icc icc-btn">
            <use href="../assets/feather-sprite.svg#target"></use>
          </svg>
          <div class="nav-hint">SearchConsole</div>
        </a>
        <button class="nav-button">
          <svg class="icc icc-btn" @click="cpu">
            <use href="../assets/feather-sprite.svg#cpu"></use>
          </svg>
          <div class="nav-hint">WDROŻENIE</div>
        </button>
        <button class="nav-button" @click="logout">
          <svg class="icc icc-btn">
            <use href="../assets/feather-sprite.svg#power"></use>
          </svg>
          <div class="nav-hint">Wyloguj</div>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
  computed: {
    authState() {
      return this.$store.state.isAuthenticated;
    },
    hrefs() {
      return this.$store.state.payload;
    }
  },
  // data() {
  //   return {
  //   }
  // },
  methods: {
    logout: function () {
      this.$router.replace({
        name: 'Dashboard'
      });
      this.$store.commit('putAuthState', false);
    },
    cpu: function () {
      this.$emit('build');
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  color: black;
  margin: 0 12px;
  // font-weight: 700;
}
.nav-link, .nav-button {
  border-bottom: 3px solid transparent;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: $brand-color;
  }
  padding: 12px 6px;
  line-height: 1;
}
.router-link-exact-active {
  border-bottom-color: $brand-color;
}
.nav-wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #777;
}
.nav-sec {
  display: flex;
}
.nav-button {
  position: relative;
  &:hover .nav-hint {
    display: block;
  }
}
.nav-hint {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  // right: 0;
  padding: 6px 12px;
  background-color: $brand-color-lighter;
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: -12px;
  text-transform: initial;
}
</style>