<template>
  <div id="app" class="layout">
    <div class="tools">
      <div class="cntr tools-wrap">
        <div class="app-name">D.CMS v0.1.0</div>
        <Countdown
          v-show="authState && tokenTime > 0"
          :counter="tokenTime"
          @refreshToken="refreshToken"
        />
      </div>
    </div>
    <Nav @build="buildAction" />
    <router-view class="main" />
    <Footer />
    <Modal
      v-show="!authState"
      :auth="auth"
      @action="logIn"
      :authMessage="auth.err"
    />
  </div>
</template>

<script>
import Countdown from "@/components/Countdown";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import refreshToken from "./core/requests/refresh-request";
import build from "./core/requests/build-request";
import signIn from "./core/requests/signin-request";

let counter;

export default {
  components: {
    Countdown,
    Nav,
    Footer,
    Modal,
  },
  name: "App",
  computed: {
    authState() {
      return this.$store.state.isAuthenticated;
    },
  },
  data() {
    return {
      auth: {
        login: null,
        password: null,
        err: "",
      },
      tokenTime: 0,
    };
  },
  methods: {
    logIn: function () {
      signIn(this.auth.login, this.auth.password)
        .then((resp) => {
          console.log(resp.data);
          if (resp.statusCode == 200) {
            const token = resp.data.token;
            const payload = resp.data.payload;
            this.$store.commit("putJwtToken", token);
            this.$store.commit("putPayload", {
              apiUrl: payload.apiUrl,
              websiteUrl: payload.websiteUrl,
              mailUrl: payload.mailUrl,
              statsUrl: payload.statsUrl,
              searchConsoleUrl: payload.searchConsoleUrl,
              githubToken: payload.githubToken,
              githubWorkflowEndpoint: payload.githubApi,
            });
            this.tokenTime = 3600;
            this.auth.err = "";
          } else {
            this.auth.err = "Nie udało się zalogować";
            console.log("Nie udało się zalogować. " + resp.data.statusCode);
          }
        })
        .catch((e) => {
          this.auth.err = "Nie udało się zalogować";
        });
    },
    refreshToken: function () {
      refreshToken()
        .then((resp) => {
          console.log(resp.data);
          this.$store.commit("putJwtToken", resp.data.token);
          this.tokenTime = 3600;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buildAction: function () {
      if (confirm("Czy jesteście gotowi na publikację zmian?")) {
        build()
          .then((resp) => {
            console.log(resp);
            console.log("buildAction method executed!");
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
  mounted() {
    counter = setInterval(() => {
      this.tokenTime -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss">
html {
  font: $prime-font; //normal 400 20/1.5 Helvetica, sans-serif;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
figure {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}
th {
  text-align: left;
}
input,
button,
textarea,
select {
  font: inherit;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
}
.sr-text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  left: -1px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin-top: -1px;
}
.layout {
  overflow-x: hidden;
}
.cntr {
  margin: 0 auto;
  width: 1200px;
  box-sizing: border-box;
}
.cntr-wide {
  padding: 1rem 12px;
}
.flex {
  display: flex;
}
.tools-wrap {
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.app-name {
  font-weight: bold;
  color: $brand-color;
}
.btn {
  padding: 6px 10px;
  margin: 4px 0;
  border: 2px solid black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17px;
  &:hover {
    background-color: $brand-color;
  }
}
.icc {
  width: 24px;
  height: 24px;
  fill: transparent;
  stroke: black;
  stroke-width: 2px;
  padding: 8px;
  background-color: transparent;
}
.icc-btn {
  &:hover {
    // stroke: white;
    // background-color: green;
    stroke: $brand-color;
  }
}
.message-errors {
  color: #dc143c;
}
</style>
