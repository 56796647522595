import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import store from '../store'

Vue.use(VueRouter)

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/')
// }

const ifAuthenticated = (to, from, next) => {
  if (store.getters.getAuthState) {
    next()
    return
  }
  // NOTE: info o niezalogowaniu
  // next()
  // from()
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/posty',
    name: 'Posts',
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "posts" */ '../views/Posts.vue'),
  },
  // {
  //   path: '/kolekcje',
  //   name: 'Collections',
  //   component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue')
  // },
  // {
  //   path: '/konfiguracja',
  //   name: 'Settings',
  //   beforeEnter: ifAuthenticated,
  //   component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  // },
  {
    path: '/nowy-post',
    name: 'AddPost',
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "addpost" */ '../views/AddPost.vue'),
    props: true
  },
  {
    path: '/posty/:id',
    name: 'PostDetail',
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "postdetail" */ '../views/PostDetail.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

export default router
