import axios from 'axios';
import store from "@/store";

export default function () {
  const BUILD_ENDPOINT = store.getters.getPayload.githubWorkflowEndpoint ?? null;
  const GITHUB_TOKEN = store.getters.getPayload.githubToken ?? null;
  console.log(BUILD_ENDPOINT, GITHUB_TOKEN);
  return new Promise((resolve, reject) => {
    if (BUILD_ENDPOINT && GITHUB_TOKEN) {
      axios({
        method: 'post',
        url: BUILD_ENDPOINT,
        data: {
          ref: 'master'
        },
        headers: {
          "Accept": "application/vnd.github.v3+json",
          "Authorization": `Token ${GITHUB_TOKEN}`
        }
      }).then(res => {
        // success
        console.log('Okej');
        resolve(res.data);
      }).catch(err => {
        // error
        const errData = err;
        alert('Nieautoryzowany dostęp');
        reject(errData);
      });
    }
  });
}