import axios from 'axios';
import store from "@/store";
import { AUTH_API_URL, AUTH_ACCESS_TOKEN } from "@/data/constants";

export default function (login, password) {
  return new Promise((resolve, reject) => {
    if (AUTH_API_URL && AUTH_ACCESS_TOKEN) {
      axios({
        method: 'post',
        baseURL: AUTH_API_URL,
        url: '/signin',
        data: {
          login,
          password
        },
        headers: {
          "Content-type": "application/json",
          "Authorization": `Token ${AUTH_ACCESS_TOKEN}`
        }
      }).then(res => {
        // success
        store.commit('putAuthState', true);
        resolve(res.data);
      }).catch(err => {
        // error
        const errData = err;
        // alert('Nieautoryzowany dostęp');
        store.commit('putAuthState', false);
        reject(errData);
      });
    }
  });
}