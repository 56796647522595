import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    jwtToken: '',
    payload: {
      apiUrl: null,
      // apiAccessToken: null,
      websiteUrl: null,
      mailUrl: null,
      statsUrl: null,
      searchConsoleUrl: null,
      githubToken: null,
      githubWorkflowEndpoint: null
    }
  },
  getters: {
    getJwtToken: state => state.jwtToken,
    getAuthState: state => state.isAuthenticated,
    getPayload: state => state.payload,
    getImageUploadEndpoint: state => {
      // console.log(state.payload.apiUrl);
      return state.payload.apiUrl ? `${state.payload.apiUrl}/admin/images/upload` : null
    }
  },
  actions: {
  },
  modules: {
  },
  mutations: {
    putJwtToken (state, value) {
      state.jwtToken = value;
    },
    putAuthState (state, value) {
      state.isAuthenticated = value;
    },
    putPayload (state, value) {
      state.payload = value;
    },
  },
})
