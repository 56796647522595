<template>
  <footer class="footer">
    <div class="cntr cntr-wide">
      <div class="footer-info">Wszelkie problemy z działaniem witryny, brakujące funkcjonalności czy inne wątpliwości proszę zgłaszać na <a href="mailto:bok@danb.pl?subject=D.CMSv0.1%20/%20Zapytanie">bok@danb.pl</a></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
.footer-info {
  // width: 350px;
  width: 335px;
}
</style>